import React from 'react'

function MainSkeleton() {
  const blink = `
    @keyframes blink {
      50% {
        opacity: 0.3;
      }
    }
  `

  const styles = {
    wrapper: {
      paddingTop: '56px',
      margin: '12px 20px',
    },
    skeleton: {
      background: 'linear-gradient(to right, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.05))',
      animation: 'blink 2s ease-in-out infinite',
      transition: 'opacity ease-in-out 0.3s',
      borderRadius: '4px',
    },
  }

  return (
    <>
      <style>{blink}</style>
      <div style={styles.wrapper}>
        <div style={{ display: 'flex' }}>
          <div style={{ ...styles.skeleton, width: '56px', height: '56px' }} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', marginLeft: '16px' }}>
            <div style={{ ...styles.skeleton, width: '188px', height: '25px' }} />
            <div style={{ ...styles.skeleton, width: '113px', height: '25px' }} />
          </div>
        </div>
        <div style={{ ...styles.skeleton, width: '100%', height: '80px', marginTop: '20px' }} />
        <div style={{ ...styles.skeleton, width: '113px', height: '25px', marginTop: '34px' }} />
        <div style={{ ...styles.skeleton, width: '100%', height: '80px', marginTop: '14px' }} />
        <div style={{ ...styles.skeleton, width: '100%', height: '136px', marginTop: '32px' }} />
        <div style={{ ...styles.skeleton, width: '113px', height: '25px', marginTop: '34px' }} />
        <div style={{ ...styles.skeleton, width: '158px', height: '25px', marginTop: '14px' }} />
        <div style={{ ...styles.skeleton, width: '158px', height: '25px', marginTop: '14px' }} />
        <div style={{ ...styles.skeleton, width: '158px', height: '25px', marginTop: '14px' }} />
        <div style={{ ...styles.skeleton, width: '158px', height: '25px', marginTop: '14px' }} />
      </div>
    </>
  )
}

export { MainSkeleton }
