import { useAtom } from 'jotai'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'

import { storageTooltipClicked } from '@/atoms'
import { sendEventLog } from '@/features/log/log.fn'
import MyLogDefinition from '@/features/log/log.my'

import * as styles from './tooltip.css'

interface TooltipProps extends PropsWithChildren {
  content: string
}

function Tooltip({ content, children }: TooltipProps) {
  const [tooltipClickedStorageValue, setTooltipClickedStorageValue] = useAtom(storageTooltipClicked)
  const { action_id } = MyLogDefinition.my
  const [visible, setVisible] = useState(false)
  const [isHiding, setIsHiding] = useState(false)

  const handleClick = useCallback(() => {
    setIsHiding(true)
    setTooltipClickedStorageValue(true)
    sendEventLog(action_id?.tapProfileTooltip)
  }, [action_id?.tapProfileTooltip, setTooltipClickedStorageValue])

  useEffect(() => {
    if (tooltipClickedStorageValue) {
      setTimeout(() => setVisible(false), 200)
    } else {
      setTimeout(() => setVisible(true), 200)
    }
  }, [tooltipClickedStorageValue])

  useEffect(() => {
    if (visible) {
      sendEventLog(action_id?.viewProfileTooltip)
    }
  }, [visible, action_id?.viewProfileTooltip])

  return (
    <>
      {children}
      {visible && (
        <button
          className={`${styles.tooltip} ${visible ? 'visible' : ''} ${isHiding ? 'hidden' : ''}`}
          onClick={handleClick}
        >
          {content}
        </button>
      )}
    </>
  )
}

export default Tooltip
