import { MyLogDefinition } from './log.my.d'

export default {
  my: {
    page_id: '/my',
    action_id: {
      tapSetting: 'tap.setting',
      tapNotification: 'tap.notification',
      tapMyinfo: 'tap.myinfo',
      tapPoint: 'tap.point',
      tapCarprofile: 'tap.carprofile',
      tapReview: 'tap.review',
      viewMyReviewReaction: 'view.my_review_reaction',
      tapMyReviewReaction: 'tap.my_review_reaction',
      viewReviewBanner: 'view.review_banner',
      tapReviewBanner: 'tap.review_banner',
      viewAvailableReview: 'view.available_review',
      tapAvailableReview: 'tap.available_review',
      viewWritingReview: 'view.writing_review',
      tapWritingReview: 'tap.writing_review',
      viewAvailableReviewMore: 'view.available_review_more',
      tapAvailableReviewMore: 'tap.available_review_more',
      viewAd: 'view_ad',
      tapAd: 'tap.ad',
      tapUsage: 'tap.usage',
      tapPay: 'tap.pay',
      tapCoupon: 'tap.coupon',
      tapWholeService: 'tap.whole_service',
      tapBizPlace: 'tap.biz_place',
      tapTimemachine: 'tap.timemachine',
      tapLaboratory: 'tap.laboratory',
      tapVoc: 'tap.voc',
      tapCommunicationEvent: 'tap.communication_event',
      tapPoiProposal: 'tap.poi_proposal',
      tapPolicy: 'tap.policy',
      tapBizPlaceBanner: 'tap.biz_place_banner',
      tapBusinessInfo: 'tap.business_info',
      viewProfileTooltip: 'view.profile_tooltip',
      tapProfileTooltip: 'tap.profile_tooltip',
    },
  },
  usage: {
    page_id: '/usage',
    action_id: {
      tapBack: 'tap.back',
      tapDriver: 'tap.driver',
      tapScooter: 'tap.scooter',
      tapEvcharge: 'tap.evcharge',
      tapParking: 'tap.parking',
      tapPickup: 'tap.pickup',
    },
  },
  communication: {
    page_id: '/communication',
    action_id: {
      tapComm: 'tap.comm',
    },
  },
  event: {
    page_id: '/event',
    action_id: {
      tapEvent: 'tap.event',
    },
  },
  communicationDetail: {
    page_id: '/communication_detail',
    action_id: {
      tapButton: 'tap.button',
    },
  },
  eventDetail: {
    page_id: '/event_detail',
    action_id: {
      tapButton: 'tap.button',
    },
  },
} as MyLogDefinition
