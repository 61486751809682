import { QueryKey, UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'

import { frontmanState } from '@/atoms/frontmanState'

function useQueryWrapper<TData, TError>(
  queryKey: QueryKey,
  queryFn: () => Promise<TData>,
  options?: UseQueryOptions<TData, TError>
): UseQueryResult<TData, TError> {
  const { isInitialized } = useAtomValue(frontmanState)
  const { enabled, ...restOptions } = options || {}
  const isEnabled = isInitialized && enabled

  return useQuery<TData, TError>(queryKey, queryFn, {
    enabled: isEnabled,
    ...restOptions,
  })
}

export { useQueryWrapper }
