import { UseQueryResult } from '@tanstack/react-query'
import { MyReviewResponse, getMyWholeReview } from '@tmap-web-lib/remote-api-client/frontman'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'

import { mockHost } from '@/apis'
import { storageMockEnabled } from '@/atoms'
import { useQueryWrapper } from '@/hooks/useQueryWrapper'
import { KEYS } from '@/react-query'

interface Props {
  useErrorBoundary?: boolean
}

function useGetMyWholeReview(props: Props): UseQueryResult<MyReviewResponse, AxiosError> {
  const storageMock = useAtomValue(storageMockEnabled)

  return useQueryWrapper<MyReviewResponse, AxiosError>(
    [KEYS.REVIEW()],
    async () => {
      if (storageMock) {
        const { data } = await mockHost.get<MyReviewResponse>('api/v1/whole/my/review')
        return data
      } else {
        const { data } = await getMyWholeReview()
        return data
      }
    },
    {
      useErrorBoundary: props?.useErrorBoundary ?? true,
    }
  )
}

export { useGetMyWholeReview }
